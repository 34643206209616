import './App.css';


function App() {
  return (
    <div className="App">
      <div className='tgme_background_wrap'></div>
      <div className='tgme_page'>
        <div className='tgme_page_photo'>
          <a href="tg://join?invite=J65vr7M57V0yOTQy">
            <div class="tgme_page_photo_image"></div>
          </a>
        </div>
        <div class="tgme_page_title" dir="auto">
          <span dir="auto">Заробляємо на хайпі</span>
        </div>
        <div class="tgme_page_description" dir="auto">Ділюся безкоштовно приватною інформацією! Топ 1 канал по крипті! Підписників за останній місяць заробили 400 000$</div>
        <div class="tgme_page_action">
          <a class="tgme_action_button_new shine" href="tg://join?invite=J65vr7M57V0yOTQy">Join Channel</a>
        </div>
      </div>
    </div>
  );
}

export default App;
